import { LoadState } from "../../constants/enums";
import {
  IExpense,
  IExpenseCategory,
  IExpenseModuleState,
  IExpenseReport,
} from "./expenseModule.types";

export const defaultExpense: IExpense = {
  expense_uuid: null,
  report_name: null,
  report_uuid: null,
  project_name: "",
  project_uuid: "",
  created_by_name: "",
  expense_date: "",
  receipt: null,
  merchant: null,
  expense_category_uuid: null,
  expense_category_name: null,
  amount: null,
  description: null,
  status: "ACTIVE",
};

export const defaultExpenseCategory: IExpenseCategory = {
  expense_category_uuid: null,
  expense_category_name: "",
  expense_category_description: null,
  status: "ACTIVE",
};

export const defaultExpenseReport: IExpenseReport = {
  report_uuid: null,
  report_name: null,
  project_uuid: "",
  project_name: "",
  project_manager_name: "",
  project_manager_uuid: "",
  finance_manager_name: "",
  finance_manager_uuid: "",
  department_uuid: "",
  department_name: "",
  expense_uuid_list: [],
  business_purpose: null,
  category: null,
  advance_amount: null,
  reimbursed_amount: null,
  description: null,
  approval_uuid: null,
  status: "REPORT_REQUESTED",
};

export const defaultExpenseModuleState: IExpenseModuleState = {
  expenseList: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  expenseData: {
    data: defaultExpense,
    loading: LoadState.NotLoaded,
    error: null,
  },
  expenseCategoryList: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },

  expenseCategoryData: {
    data: defaultExpenseCategory,
    loading: LoadState.NotLoaded,
    error: null,
  },

  expenseReportList: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  expenseReportData: {
    data: defaultExpenseReport,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
